import request from "../utils/request";

export function get_faq_list(data: JSON){
    return request({
        url: "/getFAQList",
        method: "post",
        data: data
    });
}

export function get_faq_detail(data: JSON){
    return request({
        url: "/getFAQDetail",
        method: "post",
        data: data
    });
}

export function save_faq_detail(data: FormData){
    return request({
        url: "/saveFAQDetail",
        method: "post",
        data: data
    });
}

export function export_faq_list(data: JSON){
    return request({
        url: "/exportFAQList",
        method: "post",
        data: data
    });
}