<template>
    <div class="align-items-start p-3 text-start">
        <nav aria-label="breadcrumb">
        <ol class="breadcrumb text-dark m-auto">
            <li class="breadcrumb-item active" aria-current="page">客服管理</li>
            <li class="breadcrumb-item active" aria-current="page">FAQ管理</li>
            <li class="breadcrumb-item active" aria-current="page">FAQ內容</li>
        </ol>
        </nav>
        <hr class="w-100">
        <Form @submit="save()" :validation-schema="schema" @invalid-submit="on_invalid_submit">
            <div class="col-12 row mx-auto data-form">
                <div class="mb-3">
                    <label for="faq_id" class="form-label">序號</label>
                    <input type="text" class="form-control" id="faq_id" :disabled="true" v-model="faq_data.faq_id">
                </div>
                <div class="mb-3">
                    <label for="problem_type" class="form-label"><span class="required" v-if="$route.name !== 'FAQDetailView'">*</span>問題類型</label>
                    <Field name="problem_type" v-slot="{ meta, field }" v-model="faq_data.problem_type">
                        <select
                            id="problem_type"
                            v-bind="field"
                            class="form-select"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                            :disabled="$route.name === 'FAQDetailView'"
                            @change="onChangeProblemType($event.target.value)"
                        >
                            <option value="" :disabled="true">請選擇</option>
                            <template v-for="item in problem_type_option" :key="item">
                                <option :value="item.value">{{ item.text }}</option>
                            </template>
                        </select>
                    </Field>
                    <ErrorMessage name="problem_type" class="invalid-feedback"/>
                </div>
                <div class="mb-3">
                    <label for="problem_subtype" class="form-label"><span class="required" v-if="$route.name !== 'FAQDetailView'">*</span>問題子類型</label>
                    <Field name="problem_subtype" v-slot="{ meta, field }" v-model="faq_data.problem_subtype">
                        <select
                            id="problem_subtype"
                            v-bind="field"
                            class="form-select"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                            :disabled="$route.name === 'FAQDetailView'"
                        >
                            <option value="" :disabled="true">請選擇</option>
                            <template v-for="item in problem_subtype_option" :key="item">
                                <option :value="item.value">{{ item.text }}</option>
                            </template>
                        </select>
                    </Field>
                    <ErrorMessage name="problem_subtype" class="invalid-feedback"/>
                </div>
                <div class="mb-3">
                    <label for="question" class="form-label"><span class="required" v-if="$route.name !== 'FAQDetailView'">*</span>常見問題</label>
                    <Field name="question" v-slot="{ meta, field }" v-model="faq_data.question">
                        <textarea
                            id="question"
                            v-bind="field"
                            class="form-control"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                            rows="8"
                            :disabled="$route.name === 'FAQDetailView'"
                        />
                    </Field>
                    <ErrorMessage name="question" class="invalid-feedback"/>
                </div>
                <div class="mb-3">
                    <label for="content" class="form-label"><span class="required" v-if="$route.name !== 'FAQDetailView'">*</span>答案</label>
                    <Field name="content" v-slot="{ meta, field }" v-model="faq_data.content">
                        <textarea
                            id="content"
                            v-bind="field"
                            class="form-control"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                            rows="8"
                            :disabled="$route.name === 'FAQDetailView'"
                        />
                    </Field>
                    <ErrorMessage name="content" class="invalid-feedback"/>
                </div>
                <div class="mb-3" v-if="$route.name !== 'FAQDetailView'">
                    <label for="file" class="form-label">上傳pdf檔案</label>
                    <input type="file" class="form-control" id="file" @change="file_selected" accept=".pdf" ref="file_input">
                    <div id="file_help" class="form-text">檔案大小不可超過 10 MB，檔案格式：pdf</div>
                </div>
                <div class="mb-3" v-if="faq_data.file_name !== '' && faq_data.file_name !== null && faq_data.file_name !== undefined">
                    <a :href="faq_data.file" target="_blank" rel="noreferrer noopener">
                        <strong><span>{{faq_data.file_name}}</span></strong>
                    </a>
                </div>
                <div class="mb-3" v-if="$route.params.id !== undefined">
                    <label for="count" class="form-label">點閱次數</label>
                    <input type="text" class="form-control" id="count" :disabled="true" v-model="faq_data.count">
                </div>
                <div class="mb-3" v-if="$route.params.id !== undefined">
                    <label class="form-label">更新紀錄</label>
                </div>
                <div class="mb-3 col-12 row mx-auto table-responsive" v-if="$route.params.id !== undefined">
                    <table class="table table-striped table-hover text-center align-middle">
                        <thead class="table-dark">
                            <tr>
                                <th class="col-3">人員</th>
                                <th class="col-3">時間</th>
                                <th class="col-6">事項</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in faq_data.faq_log" :key="item">
                                <td>{{ item.user }}</td>
                                <td>{{ item.time }}</td>
                                <td style="white-space: pre-wrap;">{{ item.content }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="mb-3 mx-auto text-center">
                    <button type="submit" class="btn m-2 btn-primary" :disabled="button_disabled" v-if="$route.name !== 'FAQDetailView'">送出</button>
                    <button type="button" class="btn m-2 btn-danger" v-on:click="go_back()">返回</button>
                </div>
                <div class="mb-3">
                </div>
            </div>
        </Form>
    </div>
</template>

<script>
import { 
    get_faq_detail,
    save_faq_detail
} from '../../api/faq';
import { mapActions, mapGetters } from 'vuex';
import {
    get_problem_type_option,
    get_problem_subtype_option,
} from '../../api/data';
import { Field, Form, ErrorMessage } from 'vee-validate';
import { faq_rules } from '../../rules/customer_service/faq';
import { useRouter } from 'vue-router';
import Swal from 'sweetalert2';

export default {
    components: {
        Field,
        Form,
        ErrorMessage,
    },
    data() {
        return {
            problem_type_option: [],
            problem_type_en_option: [],
            faq_data: {
                problem_type: "",
                question: "",
                content: "",
                faq_log: [],
                count: null,
                file: null,
                problem_subtype: "",
            },
            button_disabled: false,
            file: null,
            upload_file: null,
            can_go_back: false,
            problem_subtype_option: [],
            problem_subtype_en_option: [],
        }
    },
    methods: {
        ...mapActions([
            'auth/UserLogout',
        ]),
        user_logout() {
            this['auth/UserLogout']().then(() => {
                this.$router.push({ name: 'Login' });
            }, () => {
                this.$router.push({ name: 'Login' });
            })
        },
        search() {
            get_faq_detail({faq_id: this.$route.params.id}).then(response => {
                if (response.data.success === false && response.data.check === false) {
                    this.user_logout()
                } else if (response.data.success === false && response.data.error_code === "1002") {
                    this.$router.push({ name: 'FAQList' });
                } else if (response.data.success === false && response.data.error_code === "1003") {
                    this.$router.push({ name: 'FAQList' });
                } else {
                    this.faq_data = response.data.response_data;
                    this.onChangeProblemType(response.data.response_data.problem_type, true);
                }
            })
        },
        save() {
            this.button_disabled = true;
            let submit_faq_data = JSON.parse(JSON.stringify(this.faq_data));
            submit_faq_data.file = null;
            let form_data = new FormData();
            form_data.append('faq_data', JSON.stringify(submit_faq_data));
            if (this.file !== null) {
                form_data.append('upload_file', this.file, this.file.name);
            }
            save_faq_detail(form_data).then(response => {
                if (response.data.success === false && response.data.check === false) {
                    this.user_logout();
                } else {
                    const swalWithBootstrapButtons = Swal.mixin({
                        customClass: {
                            confirmButton: 'm-2 btn btn-primary',
                        },
                        buttonsStyling: false
                    })
                    swalWithBootstrapButtons.fire({
                        title: '提示訊息',
                        text: response.data.message,
                        icon: 'success',
                        showCloseButton: true,
                        allowOutsideClick: false,
                        confirmButtonText: '確認',
                    }).then((result) => {
                        if (result.isConfirmed || result.dismiss === "close") {
                            this.$router.push({ name: 'FAQList' });
                        }
                    })
                }
            }, error => {
                this.button_disabled = false;
                const swalWithBootstrapButtons = Swal.mixin({
                    customClass: {
                        cancelButton: 'm-2 btn btn-danger'
                    },
                    buttonsStyling: false
                })
                swalWithBootstrapButtons.fire({
                    title: '錯誤訊息',
                    text: error.response.data.message,
                    icon: 'error',
                    cancelButtonText: '關閉',
                    showCancelButton: true,
                    showConfirmButton: false,
                    showCloseButton: true,
                    allowOutsideClick: false,
                })
            });
        },
        on_invalid_submit() {
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    cancelButton: 'm-2 btn btn-danger'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: '錯誤訊息',
                text: '尚有欄位未填寫或是填寫錯誤！',
                icon: 'error',
                cancelButtonText: '關閉',
                showCancelButton: true,
                showConfirmButton: false,
                showCloseButton: true,
                allowOutsideClick: false,
            });
        },
        file_selected(e) {
            if (e.target.files.length != 0) {
                if ((e.target.files.item(0).size / 1024 / 1024).toFixed(2) > 10) {
                    const swalWithBootstrapButtons = Swal.mixin({
                        customClass: {
                            cancelButton: 'm-2 btn btn-danger'
                        },
                        buttonsStyling: false
                    })

                    swalWithBootstrapButtons.fire({
                        title: '錯誤訊息',
                        text: '檔案大小超過 10 MB 限制！',
                        icon: 'error',
                        cancelButtonText: '關閉',
                        showCancelButton: true,
                        showConfirmButton: false,
                        showCloseButton: true,
                        allowOutsideClick: false,
                    });
                    this.$refs.file_input.value = null;
                    this.file = null;
                    this.upload_file = null;
                } else {
                    this.file= e.target.files[0];
                }
                this.file= e.target.files[0];
            } else {
                this.$refs.file_input.value = null;
                this.file = null;
                this.upload_file = null;
            }
        },
        go_back() {
            if (this.can_go_back === true) {
                this.$router.back();
            } else {
                this.$router.push({name: "FAQList"});
            }
        },
        onChangeProblemType(problem_type, init = false) {
            if (init === false) {
                this.faq_data.problem_subtype = "";
            }
            get_problem_subtype_option({problem_type: problem_type}).then(response => {
                if (response.data.success === false && response.data.check === false) {
                    this.user_logout();
                } else {
                    this.problem_subtype_option = response.data.response_data["TW"];
                    this.problem_subtype_en_option = response.data.response_data["EN"];
                }
            })
        },
    },
    created() {
        window.scrollTo({top: 0, behavior: "smooth"});
        const router = useRouter();
        if (router.options.history.state.back !== null) {
            this.can_go_back = true;
        }
        if (this.$route.params.id !== undefined) {
            this.search();
        }
        get_problem_type_option().then(response => {
            if (response.data.success === false && response.data.check === false) {
                this.user_logout();
            } else {
                this.problem_type_option = response.data.response_data["TW"];
                this.problem_type_en_option = response.data.response_data["EN"];
            }
        })
    },
    computed: {
        ...mapGetters({
            role_list: 'role_list'
        }),
        schema() {
            return faq_rules;
        },
    }
};
</script>
